// import { builder, BuilderComponent } from "@builder.io/react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import * as React from "react";
import Link from "../components/Link/Link";
import SEO from "../components/SEO/SEO";
import Home from "../pages/home";

// builder.init(process.env.BUILDER_API_KEY);

function LandingPageGenerator(props) {
  const { data } = props;
  const { allBuilderModels } = data;
  const models = allBuilderModels;
  const landingPage = models.landingPage[0]?.content;

  return (
    <>
      <SEO
        title={landingPage && landingPage.data.title}
        description={landingPage && landingPage.data.description}
      />

      {landingPage && (
        // <BuilderComponent renderLink={Link} name="landing-page" content={landingPage} />
        <Home renderLink={Link} name="landing-page" content={landingPage} />
      )}
    </>
  );
}

export const landingPageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      landingPage(target: { urlPath: $path }, limit: 1) {
        content
      }
    }
  }
`;

LandingPageGenerator.propTypes = {
  data: PropTypes.instanceOf(LandingPageGenerator.props), // eslint-disable-line
};

export default LandingPageGenerator;
