import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";

import "./Card.scss";

const Card = (props) => {
  const [showMore, setShowMore] = useState(false);

  const { t, i18n } = useTranslation();

  const resizeLiveCounterImage = (url) => {
    if (!url) return "";

    // Cloudinary resize
    if (url.indexOf("image/upload/") !== -1) {
      return "";
    }
    //Strapi resize
    else if (url.indexOf("media.chantsocial.com") !== -1) {
      return url.replace(
        `${"https://s3.amazonaws.com/media.chantsocial.com/"}`,
        `${"https://media.chantsocial.com/"}row_`
      );
    }
    // Cloudflare resize
    else {
      return url.replace(`/${"background"}`, `/${"row"}`);
    }
  };

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const link = isMobile ? props.data.link : process.env.APP_STORE_URL;

  const renderNote = (note) => {
    if (!note) return null;
    const words = note.split(" ");
    const showEllipsis = words.length > 9 && !showMore;
    const displayedWords = showMore ? words : words.slice(0, 9);
    const displayedText = displayedWords.join(" ");

    return (
      <p>
        {displayedText}
        {showEllipsis && " ..."}
        {showEllipsis && (
          <a
            role="button"
            style={{ color: "#0080ff" }}
            onClick={() => setShowMore(!showMore)}
            onKeyDown={() => setShowMore(!showMore)}
            tabIndex={0}
          >
            {showMore ? " " + t("card.showLess") : " " + t("card.showMore")}
          </a>
        )}
      </p>
    );
  };

  function diffDate(dateStr) {
    const date1 = new Date(dateStr);
    const date2 = new Date();
    const diff = date1.getTime() - date2.getTime();
    const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));

    return t("card.remaining") + " : " + (diffInDays + 1) + " " + t("card.day");
  }

  const instagramLinks = {
    en: process.env.INSTAGRAM_EN_URL,
    tr: process.env.INSTAGRAM_TR_URL,
  };
  const instagramLink = instagramLinks[i18n.language] || instagramLinks.en;

  const shareData = {
    title: props.data.title,
    text: `${t("counterDetail.linkMessage")}`,
    url: props.data.link,
  };
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="card-cover">
      <div className="card-container">
        <Link href={`/liveCounters/counterDetail?id=${props.data.id}`}>
          <div className="image-container">
            <img
              alt="Card img"
              src={resizeLiveCounterImage(props.data.image)}
            />

            <div className="text-top">
              <p>{diffDate(props.data.endDate)}</p>
            </div>

            <div className="text-bottom-right">
              <img
                alt="Discover Icon"
                src={"/img/other-img/discoverIcon.png"}
              />
              <p>{props.data.currentCount + "/" + props.data.totalCount}</p>
            </div>

            <div className="text-bottom-left">
              <img
                alt="participantIcon"
                src={"/img/other-img/participantIcon.png"}
              />

              <p>{props.data.participantCount + " " + t("card.participant")}</p>
            </div>
          </div>
        </Link>

        <div className="card-content">
          <div className="card-title">
            <h1>{props.data.title}</h1>
          </div>

          <div className="card-body">
            {renderNote(props.data.intention.default)}
          </div>
        </div>

        <div className="btn">
          <button>
            <a href={link} target="_blank" rel="noreferrer">
              {t("card.joinNow")}
            </a>
          </button>

          {isMobile ? (
            <div className="share-icon-button" onClick={handleShare}>
              <img
                className="share-icon-img"
                alt="share Icon"
                src={"/img/other-img/share-icon.png"}
              />
            </div>
          ) : (
            <div className="icon-container">
              <a
                href={`whatsapp://send?text=${t("counterDetail.linkMessage")} ${
                  props.data.link
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Whatsapp Icon"
                  src={"/img/other-img/whatsapp-icon.png"}
                />
              </a>

              <a
                href={`https://twitter.com/intent/tweet?url=${
                  props.data.link
                }&text=${t("counterDetail.linkMessage")}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="twitter Icon"
                  src={"/img/other-img/twitter-icon.png"}
                />
              </a>

              <a href={instagramLink} target="_blank" rel="noreferrer">
                <img
                  alt="instagram Icon"
                  src="/img/other-img/instagram-icon.png"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
