import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const Home = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="box-container">
        <div className="box-item-container">
          <p className="box-title1">{t("home.homeTitle1")}</p>
          <p className="box-title2">{t("home.homeTitle2")}</p>
          <p className="box-title3">{t("home.homeTitle3")}</p>

          <div className="store-icon-box">
            <div className="appstore-icon-box">
              <a
                href={process.env.APP_STORE_URL}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="appstore-icon"
                  src={"/img/other-img/app_store_white.webp"}
                />
              </a>
            </div>

            <div className="playstore-icon-box">
              <a
                href={process.env.PLAY_STORE_URL}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="playstore-icon"
                  src={"/img/other-img/play_store_white.webp"}
                />
              </a>
            </div>
          </div>

          <div className="comment-columns-box">
            <div className="comment-box-left">
              <p className="comment-star1">★★★★★</p>
              <p className="comment-text1">{t("home.comment1")}</p>
              <p className="comment-star2">★★★★★</p>
              <p className="comment-text1">{t("home.comment2")}</p>
              <p className="comment-star3">★★★★★</p>
              <p className="comment-text1">{t("home.comment3")}</p>
            </div>

            <div className="comment-video-box">
              <video
                playsinline={true}
                autoPlay={true}
                muted={true}
                className="comment-video"
                src={"/video/app_video_1.mp4"}
              ></video>
            </div>

            <div className="comment-box-right">
              <p className="comment-star4">★★★★★</p>
              <p className="comment-text1">{t("home.comment4")}</p>
              <p className="comment-star5">★★★★★</p>
              <p className="comment-text1">{t("home.comment5")}</p>
              <p className="comment-star6">★★★★★</p>
              <p className="comment-text1">{t("home.comment6")}</p>
            </div>
          </div>

          <div className="border-box">
            <div className="border"></div>
          </div>

          <p className="box-title4">{t("home.homeTitle4")}</p>

          <div className="app-promotion-colums1">
            <div className="app-promotion-text-box">
              <p className="app-promotion-text">{t("home.homeDescription1")}</p>
            </div>
            <div className="promotion-video1-box">
              <video
                playsinline={true}
                autoPlay={true}
                muted={true}
                className="promotion-video1"
                src={"/video/promotion_video_1.mp4"}
              ></video>
            </div>
          </div>
        </div>

        <div className="border-box">
          <div className="border"></div>
        </div>

        <p className="app-promotion-text2">{t("home.homeTitle5")}</p>

        <p className="app-promotion-text3">{t("home.homeDescription2")}</p>

        <div className="app-promotion-boxes">
          <div className="app-promotion-box-left">
            <img
              className="app-promotion-box-left-img"
              src={"/img/other-img/discover-icon.webp"}
            />
            <p className="app-promotion-box-left-text1">
              {t("home.informationBoxTitle1")}
            </p>
            <p className="app-promotion-box-left-text2">
              {t("home.informationBoxText1")}
            </p>
          </div>

          <div className="app-promotion-box-right">
            <img
              className="app-promotion-box-right-img"
              src={"/img/other-img/person_icon.webp"}
            />
            <p className="app-promotion-box-right-text1">
              {t("home.informationBoxTitle2")}
            </p>
            <p className="app-promotion-box-right-text2">
              {t("home.informationBoxText2")}
            </p>
          </div>
        </div>

        <div className="border-box">
          <div className="border"></div>
        </div>

        <p className="box-title4">{t("home.homeTitle6")}</p>

        <div className="app-promotion-colums3">
          <div className="apple-watch-promotion-box">
            <p className="apple-watch-promotion-text">
              {t("home.homeDescription3")}
            </p>
          </div>
          <div className="promotion-video2-box">
            <video
              playsinline={true}
              autoPlay={true}
              muted={true}
              className="promotion-video2"
              src={"/video/apple_watch_video.mp4"}
            ></video>
          </div>
        </div>

        <div className="border-box">
          <div className="border"></div>
        </div>

        <div className="price-information-box">
          <p className="box-title4">{t("home.homeTitle7")}</p>
          <p className="price-information-text">{t("home.homeDescription4")}</p>

          <div className="annual-price-box">
            <div className="annual-price-text-box">
              <p className="annual-price-text">{t("home.bestValue")}</p>
            </div>

            <div className="annual-price-box2">
              <div className="annual-price-text-box2">
                <p className="annual-price-text2">{t("home.annual")}</p>
              </div>

              <div className="annual-price-text-box3">
                <p className="annual-price-text3">{t("home.annualPriceYr")}</p>
                <p className="annual-price-text3">{t("home.annualPriceMo")}</p>
              </div>
            </div>
          </div>

          <div className="monthly-price-box">
            <div className="monthly-price">
              <div className="monthly-price-text-box1">
                <p className="monthly-price-text1">{t("home.monthly")}</p>
              </div>

              <div className="monthly-price-text-box2">
                <p className="monthly-price-text2">{t("home.monthlyPrice")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
