import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const ContentList = ({ filteredData }) => {
  const [linkName, setLinkName] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    titleNameFunc();
  }, []);

  function titleNameFunc() {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const parts = path.split("/");
      const selected = parts[1];
      const title = selected.charAt(0).toUpperCase() + selected.slice(1);
      setLinkName(selected);
    }
  }
  if (filteredData === undefined) return <div></div>;

  return (
    <div>
      <div className="footer-container">
        <div className="footer-link-container">
          <a className="footer-link-title" href="/">
            {t("home.home")}
          </a>
          <p className="footer-link-title">/</p>
          <a className="footer-link-title" href={"/" + linkName}>
            {t("menu.blog")}
          </a>
        </div>

        <div className="title-container">{t("menu.blog")}</div>
      </div>

      <div className="blog-container">
        {filteredData
          .filter((item) => item.slug.indexOf("tr") === -1)
          .map(function (d, idx) {
           
            return (
              <div className="blog-content-container">
                <img
                  className="blog-content-image"
                  src={"/img/other-img/app_icon.png"}
                />
                <p className="blog-content-header">{d.title}</p>

                <p className="blog-content-description">
                  {d.headline}
                </p>
                <a className="blog-content-button-container" href={d.slug}>
                  <p className="blog-content-see-detail">See Detail</p>

                  <img
                    className="blog-content-arrow-image"
                    src={"/img/other-img/arrow_icon.png"}
                  />
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ContentList;