import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import { loadStripe } from "@stripe/stripe-js";

import config from "../../config";

import Row from "./prebuilt/Row";
import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";
import SuccessField from "./prebuilt/SuccessField";

// The items the customer wants to buy
const items = [{ id: "xl-tshirt" }];

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const stripePromise = loadStripe(config.stripeSecretKey);

const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

const CheckoutForm = () => {
  const [isSuccess, setSuccess] = useState(false);
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    // const billingDetails = {
    //   name: ev.target.name.value,
    //   email: ev.target.email.value,
    //   address: {
    //     city: ev.target.city.value,
    //     line1: ev.target.address.value,
    //     state: ev.target.state.value,
    //     postal_code: ev.target.zip.value,
    //   },
    // };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {
      const response = await fetch(config.createPaymentIntentUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items,
          descriptor: ev.target.descriptor.value,
          amount: ev.target.price.value * 100,
        }),
      });
      const { clientSecret } = await response.json();

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      setSuccess(true);
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const iframeStyles = {
    base: {
      color: "#fff",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#4B8EF9",
      color: "#4B8EF9",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div>
        {isSuccess ? (
          <SuccessField />
        ) : (
          <div>
            <Row>
              <BillingDetailsFields />
            </Row>
            <Row>
              <CardElementContainer>
                <CardElement
                  options={cardElementOpts}
                  onChange={handleCardDetailsChange}
                />
              </CardElementContainer>
            </Row>
            {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
            <Row>
              <SubmitButton disabled={isProcessing || !stripe}>
                {isProcessing ? "Processing..." : `Pay`}
              </SubmitButton>
            </Row>
          </div>
        )}
      </div>
    </form>
  );
};

export default Wrapper;
