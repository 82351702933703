import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import moment from "moment";
import { signInWithEmailAndPassword } from "firebase/auth";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Card from "../../components/Card/Card";
import { db, auth } from "../../helpers/firebase";

import "./styles.scss";

const LiveCounters = (props) => {
  const [data, setData] = useState([]);
  const [country, setCountry] = useState({
    value: `all`,
    label: "default",
  });

  const options = [
    { value: "tr-TR", label: "🇹🇷Türkçe" },
    { value: "en-EN", label: "🇺🇸English" },
    { value: "all", label: "🌐All" },
  ];

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await signInWithEmailAndPassword(
          auth,
          process.env.FIREBASE_USER_NAME,
          process.env.FIREBASE_USER_PASSWORD
        );
        console.log("logged in");

        const q = query(
          collection(db, "counters"),
          where("isLive", "==", true),
          where("isCompleted", "==", false),
          where("isDeleted", "==", false),
          where("endDate", ">=", moment().format("YYYY/MM/DD")),
          limit(100)
        );

        const querySnapshot = await getDocs(q);

        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            id: doc.data().id,
            title: doc.data().title,
            intention: JSON.parse(doc.data().intention),
            note: JSON.parse(doc.data().note),
            image: doc.data().bgImageUrl,
            link: doc.data().liveShareLink,
            currentCount: doc.data().currentCount,
            totalCount: doc.data().totalCount,
            participantCount: doc.data().participantCount,
            endDate: doc.data().endDate,
            isLive: doc.data().isLive,
            isPublic: doc.data().isPublic,
          });
        });
        setData(data);
      } catch (error) {
        console.log("unable to login");
        console.log(error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
      }
    };
    fetchData();
  }, []);

  // version1
  let cards = data;
  let selectCountry = country.value;

  if (selectCountry === "tr-TR") {
    cards = data.filter(
      (card) =>
        card.currentCount <= card.totalCount &&
        card.participantCount !== undefined &&
        card.note.tr !== undefined &&
        card.note.tr !== null &&
        card.image !== "" &&
        !card.image.startsWith("file")
    );
  } else if (selectCountry === "en-EN") {
    cards = data.filter(
      (card) =>
        card.currentCount < card.totalCount &&
        card.participantCount !== undefined &&
        card.note.en !== undefined &&
        card.note.en !== null &&
        card.image !== "" &&
        !card.image.startsWith("file")
    );
  } else {
    cards = data.filter(
      (card) =>
        card.currentCount < card.totalCount &&
        card.participantCount !== undefined &&
        card.image !== "" &&
        !card.image.startsWith("file")
    );
  }

  return (
    <div className="main-container">
      <div className="select-container">
        <Select
          options={options}
          defaultValue={country.value}
          onChange={setCountry}
          className="select"
          placeholder={t("liveCounters.selectLanguage")}
        />
      </div>
      <div className="card">
        {cards.map((card) => (
          <Card data={card} />
        ))}
      </div>
    </div>
  );
};

export default LiveCounters;
