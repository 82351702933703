import React from "react";
import FormField from "./FormField";

const BillingDetailsFields = () => {
  return (
    <>
      <FormField
        name="price"
        label="Price $"
        type="number"
        placeholder="1"
        required
      />
      {/* <FormField name="name" label="Name" type="text" placeholder="Jane Doe" /> */}
      <FormField
        name="descriptor"
        label="Descriptor"
        type="text"
        placeholder="Custom descriptor"
        required
      />
    </>
  );
};

export default BillingDetailsFields;
