import React from "react";
import "./styles.scss";

const TermsOfService = (props) => {
  return (
    <div className="main-container">
      <div className="container">
        <div className="description">
          <div className="subtitle">Terms of Service</div>
          <div className="subtitle-2">
            <p>
              By using the Zikir App (“Service”), you are agreeing to be bound
              by the following terms and conditions (“Terms of Service”). Zikir
              App, LLC (“Company”) reserves the right to update and change these
              Terms of Service without notice.
            </p>
            <br />
            <p>
              Violation of any of the terms below may result in the termination
              of your account.
            </p>

            <h3 className="section-title">
              <strong>Account Terms</strong>
            </h3>
            <p>
              You are responsible for maintaining the security of your account
              and password. The Company cannot and will not be liable for any
              loss or damage from your failure to comply with this security
              obligation.
            </p>
            <br />
            <p>
              You are responsible for all content posted and activity that
              occurs under your account (even when content is posted by others
              who have their own logins under your account).
            </p>
            <br />
            <p>
              You may not use the Service for any illegal purpose or to violate
              any laws in your jurisdiction (including but not limited to
              copyright laws).
            </p>
            <br />
            <p>
              You must provide your legal full name, a valid email address, and
              any other information requested in order to complete the signup
              process.
            </p>
            <br />
            <p>
              Your login may only be used by one person – a single login shared
              by multiple people is not permitted. You may create separate
              logins for as many people as you’d like.
            </p>
            <br />
            <p>
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted.
            </p>

            <h3 className="section-title">
              <strong>Cancellation and Termination</strong>
            </h3>
            <p>
              You are responsible for maintaining the security of your account
              and password. The Company cannot and will not be liable for any
              loss or damage from your failure to comply with this security
              obligation.
            </p>
            <br />
            <p>
              You are responsible for all content posted and activity that
              occurs under your account (even when content is posted by others
              who have their own logins under your account).
            </p>
            <br />
            <p>
              You may not use the Service for any illegal purpose or to violate
              any laws in your jurisdiction (including but not limited to
              copyright laws).
            </p>
            <br />
            <p>
              You must provide your legal full name, a valid email address, and
              any other information requested in order to complete the signup
              process.
            </p>
            <br />
            <p>
              Your login may only be used by one person – a single login shared
              by multiple people is not permitted. You may create separate
              logins for as many people as you’d like.
            </p>
            <br />
            <p>
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted.
            </p>
            <br />
            <p>
              You must provide your legal full name, a valid email address, and
              any other information requested in order to complete the signup
              process.
            </p>
            <br />
            <p>
              Your login may only be used by one person – a single login shared
              by multiple people is not permitted. You may create separate
              logins for as many people as you’d like.
            </p>
            <br />
            <p>
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted.
            </p>

            <h3 className="section-title">
              <strong>Modifications to the Service and Prices</strong>
            </h3>
            <p>
              The Company reserves the right at any time and from time to time
              to modify or discontinue, temporarily or permanently, any part of
              the Service with or without notice.
            </p>
            <br />
            <p>
              Prices of all Services are subject to change upon 30 days notice
              from us. Such notice may be provided at any time by posting the
              changes to the Company web site or the Service itself.
            </p>
            <br />
            <p>
              The Company shall not be liable to you or to any third party for
              any modification, price change, suspension or discontinuance of
              the Service.
            </p>

            <h3 className="section-title">
              <strong>Copyright and Content Ownership</strong>
            </h3>
            <p>
              All content posted on the Service must comply with U.S. copyright
              law.
            </p>
            <br />
            <p>
              We claim no intellectual property rights over the material you
              provide to the Service. All materials uploaded remain yours.
            </p>
            <br />
            <p>
              The Company does not pre-screen content, but reserves the right
              (but not the obligation) in their sole discretion to refuse or
              remove any content that is available via the Service.
            </p>
            <br />
            <p>
              The look and feel of the Service is copyright© , LLC. All rights
              reserved. You may not duplicate, copy, or reuse any portion of the
              HTML, CSS, JavaScript, or visual design elements without express
              written permission from the Company.
            </p>

            <h3 className="section-title">
              <strong>General Conditions</strong>
            </h3>
            <p>
              Your use of the Service is at your sole risk. The service is
              provided on an “as is” and “as available” basis.
            </p>
            <br />
            <p>Technical support is only provided via email.</p>
            <br />
            <p>
              You understand that the Company uses third party vendors and
              hosting partners to provide the necessary hardware, software,
              networking, storage, and related technology required to run the
              Service.
            </p>
            <br />
            <p>You must not modify, adapt or hack the Service.</p>
            <br />
            <p>
              You must not modify another website so as to falsely imply that it
              is associated with the Service or the Company.
            </p>
            <br />
            <p>
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service without the express written permission by the
              Company.
            </p>
            <br />
            <p>
              We may, but have no obligation to, remove content and accounts
              that we determine in our sole discretion are unlawful or violates
              any party’s intellectual property or these Terms of Service.
            </p>
            <br />
            <p>
              Verbal, physical, written or other abuse (including threats of
              abuse or retribution) of any Service customer, Company employee or
              officer will result in immediate account termination.
            </p>
            <br />
            <p>
              You understand that the technical processing and transmission of
              the Service, including your content, may be transferred
              unencrypted and involve (a) transmissions over various networks;
              and (b) changes to conform and adapt to technical requirements of
              connecting networks or devices.
            </p>
            <br />
            <p>
              We reserve the right to temporarily disable your account if your
              usage significantly exceeds the average usage of other Service
              customers. Of course, we’ll reach out to the account owner before
              taking any action except in rare cases where the level of use may
              negatively impact the performance of the Service for other
              customers.
            </p>
            <br />
            <p>
              The Company does not warrant that (i) the service will meet your
              specific requirements, (ii) the service will be uninterrupted,
              timely, secure, or error-free, (iii) the results that may be
              obtained from the use of the service will be accurate or reliable,
              (iv) the quality of any products, services, information, or other
              material purchased or obtained by you through the service will
              meet your expectations, and (v) any errors in the Service will be
              corrected.
            </p>
            <br />
            <p>
              You expressly understand and agree that the Company shall not be
              liable for any direct, indirect, incidental, special,
              consequential or exemplary damages, including but not limited to,
              damages for loss of profits, goodwill, use, data or other
              intangible losses (even if the Company has been advised of the
              possibility of such damages), resulting from: (i) the use or the
              inability to use the service; (ii) the cost of procurement of
              substitute goods and services resulting from any goods, data,
              information or services purchased or obtained or messages received
              or transactions entered into through or from the service; (iii)
              unauthorized access to or alteration of your transmissions or
              data; (iv) statements or conduct of any third party on the
              service; (v) or any other matter relating to the service.
            </p>
            <br />
            <p>
              The failure of the Company to exercise or enforce any right or
              provision of the Terms of Service shall not constitute a waiver of
              such right or provision. The Terms of Service constitutes the
              entire agreement between you and the Company and govern your use
              of the Service, superceding any prior agreements between you and
              the Company (including, but not limited to, any prior versions of
              the Terms of Service).
            </p>
            <br />
            <p>
              Questions about the Terms of Service should be sent to
              support@zikirapp.com.
            </p>
            <br />
            <p>
              Any new features that augment or enhance the current Service,
              including the release of new tools and resources, shall be subject
              to the Terms of Service. Continued use of the Service after any
              such changes shall constitute your consent to such changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
