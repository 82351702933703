import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { db } from "../../../helpers/firebase";

import "./styles.scss";

const CounterDetail = (props) => {
  const [data, setData] = useState([]);

  const { t, i18n } = useTranslation();

  const urlParams = new URLSearchParams(props.location.search);
  const id = urlParams.get("id");

  useEffect(() => {
    const q = query(collection(db, "counters"), where("id", "==", id));

    getDocs(q)
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            title: doc.data().title,
            intention: JSON.parse(doc.data().intention),
            note: JSON.parse(doc.data().note),
            link: doc.data().liveShareLink,
            currentCount: doc.data().currentCount,
            totalCount: doc.data().totalCount,
            participantCount: doc.data().participantCount,
            endDate: doc.data().endDate,
          });
        });

        setData(data);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [data]);

  function redirectAppStoreOrPlayStore() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS cihazı
      window.location.href = process.env.APP_STORE_URL;
    } else if (/android/i.test(userAgent)) {
      // Android cihazı
      window.location.href = process.env.PLAY_STORE_URL;
    } else {
      // iOS veya Android cihazı değil
      console.log("Bu cihaz desteklenmiyor.");
    }
  }

  const instagramLinks = {
    en: process.env.INSTAGRAM_EN_URL,
    tr: process.env.INSTAGRAM_TR_URL,
  };
  const instagramLink = instagramLinks[i18n.language] || instagramLinks.en;

  return (
    <div>
      {data.length > 0 && (
        <div>
          <div className="top-column">
            <div className="text-box">
              <p className="text-box-p">{t("contentDetail.topHeadline")}</p>
            </div>

            <div className="top-column-imgBox">
              <a
                href={process.env.APP_STORE_URL}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="App Store"
                  className="top-column-img"
                  src={"/img/other-img/app_store_black.png"}
                />{" "}
              </a>

              <a
                href={process.env.PLAY_STORE_URL}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="App Store"
                  className="top-column-img2"
                  src={"/img/other-img/play_store_black.png"}
                />
              </a>
            </div>
          </div>

          <div className="body-container">
            <div className="body-container-box">
              <div className="body-container-itemBox">
                <p className="body-title">{data[0].title}</p>

                <div className="body-item-box">
                  <div className="body-item-descriptionBox">
                    <p className="body-content">{data[0].intention.default}</p>

                    <p className="description-datail">
                      <img
                        alt="Participant Icon"
                        className="description-icon"
                        src={"/img/other-img/participantIcon_black.png"}
                      />
                      {t("card.participant") + " " + ":" + " "}

                      {data[0].participantCount}
                    </p>

                    <p className="description-datail">
                      <img
                        alt="Discover Icon"
                        className="description-icon"
                        src={"/img/other-img/discover_icon_black.png"}
                      />
                      {t("counterDetail.remainingDhikr") + " " + ":" + " "}
                      {data[0].currentCount} / {data[0].totalCount}
                    </p>

                    <div className="body-storeIcons-box">
                      <a
                        href={process.env.APP_STORE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="App store"
                          className="body-storeIcon-img"
                          src={"/img/other-img/app_store_black.png"}
                        />
                      </a>

                      <a
                        href={process.env.PLAY_STORE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="Play Store"
                          className="body-storeIcon-img2"
                          src={"/img/other-img/play_store_black.png"}
                        />
                      </a>
                    </div>
                  </div>
                  <a
                    role="button"
                    onClick={redirectAppStoreOrPlayStore}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="App Img"
                      className="body-img-box"
                      src={"/img/other-img/mockup_2.png"}
                    />
                  </a>
                </div>

                <div className="body-sentMessageBox">
                  <a
                    className="share-link"
                    href={`whatsapp://send?text=${
                      t("counterDetail.linkMessage") + " " + ": "
                    } ${data[0].link}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Whatsapp Icon"
                      src={"/img/other-img/whatsapp_logo.png"}
                    />
                  </a>

                  <a
                    className="share-link"
                    href={`https://twitter.com/intent/tweet?url=${
                      data[0].link
                    }&text=${t("counterDetail.linkMessage") + " " + ": "}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="twitter Icon"
                      src={"/img/other-img/twitter_logo.png"}
                    />
                  </a>

                  <a
                    className="sms-share-link"
                    href={instagramLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="instagram Icon"
                      src={"/img/other-img/instagram-icon.png"}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CounterDetail;
