import * as React from "react";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import packageJson from "../../../package.json";

import "./footer.scss";

const Footer = () => {
  const buildDate = Date.now();
  const { t } = useTranslation();

  return (
    <Micro>
      <footer className="footer micro">
        <div className="container">
          <div className="grid-4">
            <div className="align-vertical">
              <div>
                <img
                  loading="lazy"
                  height="60"
                  width="75"
                  src="https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/bb1d3ab6-187d-451f-6ec4-7c1918f9f200/websiteLogo"
                  alt={`Example Company Inc. v${packageJson.version} build time: ${buildDate}`}
                />

                <div className="padding-top-1rem card-neumorphic-dark padding-1rem">
                  <h6>Chant Social System</h6>
                  <p>
                    zikir.com is an all-in-one chant that powers live
                    operations.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h5 className="text-white fw-600">Site</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/termsOfService"
                  >
                    {t("footer.termsOfService")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/privacyPolicy"
                  >
                    {t("footer.privacyPolicy")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="https://help.zikir.com/"
                    target="_blank"
                  >
                    {t("footer.help")}
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h5 className="title">{t("footer.guides")}</h5>
            </div>
          </div>

          <hr />

          <div className="grid-3 padding-top-1rem flex flex-middle ">
            <div>
              <p className="padding-1rem">
                © ChantSocial Inc. <strong>2020</strong> {t("footer.reserved")}
              </p>
            </div>

            <div className="text-center">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/sufimeditation_"
              >
                <img
                  className="footer-icon"
                  src="https://www.zikir.com/img/other-img/twitter_r1qbd6.svg"
                  alt="Example Company Inc. Twitter Official Social Media Account"
                />
              </Link>

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/chantsocial"
              >
                <img
                  className="footer-icon"
                  src="https://www.zikir.com/img/other-img/linkedin_sprcw8.svg"
                  alt="Example Company Inc. Linkedin Official Social Media Account"
                />
              </Link>
            </div>

            <div className="text-right">
              Designed in{" "}
              <span role="img" aria-label="Sun">
                ☀️
              </span>{" "}
              California &amp; Built Globally With{" "}
              <span role="img" aria-label="Heart">
                ❤️
              </span>
            </div>
          </div>
        </div>
      </footer>
    </Micro>
  );
};

export default Footer;
