import React from "react";

import pagesData from "../../../data/data-pages.json";
import blogData from "../../../data/data-blog.json";

import "./recommendations.scss";

const Recommendations = (props) => {
  const data = props.linkName === "blog" ? blogData : pagesData;
  const foundIndex = data.findIndex((item) => item.id === props.id);
  const beforeItems = data.slice(0, foundIndex);
  const afterItems = data.slice(foundIndex + 1);
  const top = beforeItems.slice(foundIndex - 5, foundIndex);
  const bottom = afterItems.slice(0, 5);

  return (
    <div>
      <div className="recommendations-box">
        {top.map((item) => (
          <div key={item.id} className="recommendations-title">
            <a href={item.slug}>{item.id}</a>
          </div>
        ))}
      </div>

      <div className="recommendations-box">
        {bottom.map((item) => (
          <div key={item.id} className="recommendations-title">
            <a href={item.slug}>{item.id}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
