/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { TextAlignJustified, Cross } from "akar-icons";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import config from "../../config";

const { logoBlue } = config;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState("none");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown("remote");
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = "auto";
  };

  return (
    <Micro>
      <header className="remote-header">
        <div className="menu-container">
          <div className="navigation">
            <figure className="logo">
              <Link
                onClick={handleLinkClick}
                href="/"
                title="Chant Social - Sufi Meditation"
              >
                <img
                  height="100"
                  width="90"
                  loading="lazy"
                  src={logoBlue}
                  alt="SufiMeditation"
                />
              </Link>
            </figure>

            <menu className="menu" role="navigation">
              <Hamburger
                className="responsive-toggle"
                setHamburgerStatus={setHamburgerStatus}
                hamburgerOpen={hamburgerOpen}
              >
                <ul className="main-menu">
                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        href="/"
                        onClick={() => (isMobile = true)}
                      >
                        {t("menu.theApp")}
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        onClick={() =>
                          navigator.share({
                            title: t("menu.theApp"),
                            url: "https://www.zikir.com",
                          })
                        }
                      >
                        {t("menu.share")}
                      </Link>
                    </span>
                  </li>

                  {/* <li>
                    <span>
                      <Link className="menu-link" href="/about-us/">
                        {t("menu.aboutUs")}
                      </Link>
                    </span>
                  </li> */}

                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        href="/blog"
                        onClick={() => (isMobile = true)}
                      >
                        {t("menu.blog")}
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        href="/pages"
                        onClick={() => (isMobile = true)}
                      >
                        {t("menu.pages")}
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        href="/liveCounters"
                        onClick={() => (isMobile = true)}
                      >
                        {t("menu.liveCounters")}
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link
                        className="menu-link"
                        href="https://help.zikir.com/"
                      >
                        {t("menu.resources")}
                      </Link>
                    </span>
                  </li>
                </ul>
              </Hamburger>
            </menu>

            <aside className="actions text-center">
              <Link
                className="menu-link cta-button"
                target="_blank"
                href="https://buy.stripe.com/8wM7vvgfk5Dv6oE28b"
              >
                <span className="my-1rem" style={{ fontSize: "14px" }}>
                  {t("menu.donate")}
                </span>
              </Link>
            </aside>
          </div>
        </div>
      </header>
    </Micro>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = "auto";
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = "hidden";
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: "0.5rem" }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: "0.5rem 0 0.5rem 0.7rem" }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? "block" : "none",
          margin: "none",
          padding: "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
