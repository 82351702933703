import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Recommendations from "../RecommendationsView/recommendations";

import "./ContentDetailView.scss";

const ContentDetailView = (props) => {
  const { t } = useTranslation();
  const [linkName, setLinkName] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [changeTitle, setChangeTitle] = useState("");

  function redirectAppStoreOrPlayStore() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS
      window.location.href = process.env.APP_STORE_URL;
    } else if (/android/i.test(userAgent)) {
      // Android
      window.location.href = process.env.PLAY_STORE_URL;
    } else {
      // Not an iOS or Android device
      console.log("This device is not supported.");
    }
  }

  useEffect(() => {
    titleNameFunc();
    changeTitleData(props.data.id);
  }, []);

  function titleNameFunc() {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const parts = path.split("/");
      const selected = parts[1];
      const title = selected.charAt(0).toUpperCase() + selected.slice(1);
      setLinkTitle(title);
      setLinkName(selected);
    }
  }
  function changeTitleData(data) {
    const title = data;
    const turkishCharacters = {
      ç: "c",
      ğ: "g",
      ı: "i",
      ö: "o",
      ş: "s",
      ü: "u",
      Ç: "C",
      Ğ: "G",
      İ: "I",
      Ö: "O",
      Ş: "S",
      Ü: "U",
    };

    let changedTitle = title.toLowerCase().replace(/ /g, "-");

    for (const [turkishChar, englishChar] of Object.entries(
      turkishCharacters
    )) {
      changedTitle = changedTitle.replace(
        new RegExp(turkishChar, "g"),
        englishChar
      );
    }

    setChangeTitle(changedTitle);
  }

  return (
    <div>
      <div>
        <div className="top-column">
          <div className="text-box">
            <p className="text-box-p">{t("contentDetail.topHeadline")}</p>
          </div>

          <div className="top-column-imgBox">
            <a
              href={process.env.APP_STORE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="App Store"
                className="top-column-img"
                src={"/img/other-img/app_store_black.png"}
              />
            </a>

            <a
              href={process.env.PLAY_STORE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="App Store"
                className="top-column-img2"
                src={"/img/other-img/play_store_black.png"}
              />
            </a>
          </div>
        </div>

        <div className="footerContainer">
          <div className="footerLink">
            <a className="footerLinkName" href="/">
              {t("home.home")}
            </a>
            <p>/</p>
            <a className="footerLinkName" href={"/" + linkName}>
              {linkTitle === "Pages" ? t("menu.pages") : t("menu.blog")}
            </a>
            <p>/</p>
            <a
              className="footerLinkName"
              href={"/" + linkName + "/" + changeTitle}
            >
              {props.data.id}
            </a>
          </div>
        </div>

        <div className="body-container">
          <div className="body-container-box">
            <div className="body-container-itemBox">
              <p className="body-title">{props.data.id}</p>
              <div className="body-item-box">
                <div className="body-item-descriptionBox">
                  <p className="body-content">{props.data.headline}</p>
                  <p className="body-content2">{props.data.contents}</p>
                </div>
              </div>

              <div className="body-item-box">
                <div className="body-item-descriptionBox">
                  <p className="body-content">{t("contentDetail.headline")}</p>
                  <p className="body-content2">
                    {t("contentDetail.description")}
                  </p>

                  <div className="body-storeIcons-box">
                    <a
                      href={process.env.APP_STORE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="App store"
                        className="body-storeIcon-img"
                        src={"/img/other-img/app_store_black.png"}
                      />
                    </a>

                    <a
                      href={process.env.PLAY_STORE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="Play Store"
                        className="body-storeIcon-img2"
                        src={"/img/other-img/play_store_black.png"}
                      />
                    </a>
                  </div>
                </div>

                <a
                  role="button"
                  onClick={redirectAppStoreOrPlayStore}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="App Img"
                    className="body-img-box"
                    src={"/img/other-img/mockup_2.png"}
                  />
                </a>
              </div>

              <div className="recommendations-container">
                <p className="recommendations-text">
                  {t("contentDetail.recommendations")}
                </p>

                <Recommendations linkName={linkName} id={props.data.id} />
              </div>

              {/* <div className="body-sentMessageBox">
                <a
                  className="share-link"
                  href={props.data.shareOnWhatsapp}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Whatsapp Icon"
                    src={"/img/other-img/whatsapp_logo.png"}
                  />
                </a>

                <a
                  className="share-link"
                  href={props.data.shareOnTwitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="twitter Icon"
                    src={"/img/other-img/twitter_logo.png"}
                  />
                </a>

                <a
                  className="sms-share-link"
                  href={props.data.shareOnSms}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="facebook Icon" src={"/img/other-img/message.png"} />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentDetailView;
