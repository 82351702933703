import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 475px;
  margin: 30px auto 0 auto;
  text-align: center;
  color: #fff;
`;

const Title = styled.div`
  color: green;
  font-size: 58px;
`;

const Message = styled.div`
  color: gray;
  margin-top: 40px;
`;

const SuccessField = () => {
  return (
    <Container>
      <Title>Congrats!</Title>
      <Message>Stripe has successfully processed your payment.</Message>
    </Container>
  );
};

export default SuccessField;
