// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.zikir.com";
const createPaymentIntentUrlDefault =
  "https://zikir.global.ssl.fastly.net/create-payment-intent";
const stripeSecretKeyDefault =
  "pk_live_51ItR7PIz16o6wjIrL0qCqsTtnPRaZ0uUYmTGIwTdCQp0U0zX57IzE8KsbYkeel9iuI2ie2Mwz9PZs11rI0xIZIzy00wQrNKlhz";
const config = {
  title: "Sufi Meditation: #1 Dhikr & Prayer App",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "Chant Social Inc.",
  description: "Chant Social Description",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  createPaymentIntentUrl: process.env.CREATE_PAYMENT_INTENT_URL
    ? process.env.CREATE_PAYMENT_INTENT_URL
    : createPaymentIntentUrlDefault,
  stripeSecretKey: process.env.STRIPE_SECRET_KEY
    ? process.env.STRIPE_SECRET_KEY
    : stripeSecretKeyDefault,
  image:
    "https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/c3ea9ecf-74fc-4fd2-41d7-5467d9779500/thumbnail", // Path to your image you placed in the 'static' folder
  logoBlue:
    "https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/c3ea9ecf-74fc-4fd2-41d7-5467d9779500/websiteLogo",
  postThumbnail:
    "https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/a47e2df9-19f5-4303-70ac-3474b2a09a00/banner",
  twitterUsername: "@SufiMeditation_",
  blogDesktopBanner:
    "//cdn.bannersnack.com/banners/bdzm2unx4/embed/index.html?userId=42465324&t=1610719219",
  blogMobileBanner:
    "//cdn.bannersnack.com/banners/bu9sgifn7/embed/index.html?userId=42465324&t=1610571847",
  // airtableTables: ["Blog", "Verses", "Dhikrs"]
  airtableTables: ["Pages", "Blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: [
    "slug",
    "title",
    "description",
    "headline",
    "published",
    "shareOnTwitter",
    "imageUrl",
    "shareOnWhatsapp",
    "shareOnSms",
    "id",
    "zikirs",
    "template",
    "contents",


  ],
};

module.exports = config;
